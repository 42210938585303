import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell de 3 a 12 cm de diàmetre, primer globós, després convex i finalment pla. La cutícula és de color crema als marges i quasi marró a la zona central del capell. Les làmines, molt nombroses, són de color canyella, fines i atapeïdes. El peu, del mateix color que el capell, és cilíndric i corbat de 6-12 x 1-2 cm i porta un anell a la part alta. Aquest és persistent i d’igual tonalitat, tacat de marró a algunes zones pel dipòsit d’espores. Cal destacar el gust d’avellana de la seva carn. Les espores són de color ocraci clar en massa, el·líptiques, de 8-11 x 5-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      